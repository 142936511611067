import React from "react"
import { Row, Col, Container, Button} from 'react-bootstrap';
import ContactUs from "./ContactUs";
import banner from '../assets/images/services/rad-banner.png';

export default function() {

    const [modalShow, setModalShow] = React.useState(false);
    return (
        <div className="banner-rad single-service-banner">  
            <Container> 
                <Row>
                    <Col xs={12} md={12} lg={6} className="banner">
                        <h1 className="name">Research & Development</h1>
                        <p className="paragraph">Facilitating the process of turning ideas into products by constantly seeking knowledge to develop, design, and enhance our products, services, technologies, and processes.</p>
                        <Button   
                            className="primary-btn-s contact-us-btn"
                            onClick={() => setModalShow(true)} 
                        > Contact us </Button>
                    </Col>
                    <Col xs={12} md={12} lg={6} className="banner-img"><img src={banner} alt="rad banner" /></Col>
                </Row> 
                <ContactUs  show={modalShow} onHide={() => setModalShow(false)} />
            </Container>
        </div>
    )
}
import React, {useState} from "react"
import { Row, Col, Container } from "react-bootstrap"
export default function () {
  const [fullTextShowing, setFullTextShowing] = useState(false);
  return (
    <div className="rad-description">
      <Container>
        <Row>
          <span class="description-text">
            Our implementation team created blueprints of time-consuming
            processes and brainstormed ideas to speedup, improve and ascend.
          </span>
          <span class={`description-text mobile-show-${fullTextShowing}`}>
            Then, we turned our initial findings into a plan for implementing or
            integrating them into any online business. By automating processes
            and reducing daily time-consuming tasks, we support our client’s
            efforts to streamline their workflow. 
          </span>
          <span class={`description-text mobile-show-${fullTextShowing}`}>
          Our innovative ideas let us
            and our clients <strong>do more with less</strong> and focus on
            bigger projects.
          </span>

          <a href="" className="read-more" onClick={(e)=>{e.preventDefault();setFullTextShowing((value)=>!value)}}>
            { fullTextShowing ? "Hide" : "Read More" } 
          </a>
        </Row>
      </Container>
    </div>
  )
}

import React, { useState, useEffect } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import { Row, Col, Container } from "react-bootstrap"
import ReactReadMoreReadLess from "react-read-more-read-less"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

import "swiper/modules/navigation/navigation.scss"
import "swiper/modules/scrollbar/scrollbar.scss"
import "swiper/modules/mousewheel/mousewheel.scss"
import { Pagination, Scrollbar, Mousewheel } from "swiper"

export default () => {
  const [sliderDirection, setSliderDirection] = useState(getScreenSize())

  const handleResize = () => {
    setSliderDirection(getScreenSize())
  }

  function getScreenSize() {
    if (typeof window !== "undefined") {
      if (window.matchMedia("(min-width: 992px)").matches) {
        return "vertical"
      } else {
        return "horizontal"
      }
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
  }, [])

  const [cards, _] = useState([
    {
      id: 1,
      icon: "testful-framework-icon.svg",
      title: "Testful",
      text:
        "Testful’s personalization capabilities help you create experiences that look great, work well, and adapt across devices. Testful’s no-code experience templates automate the delivery of multiple experiments in one go, with vast customization capabilities for the everyday user. This gives everyone the flexibility to update and deploy proven experimental ideas across any site or market.",
      link: "https://testful.co.uk/",
    },
    {
      id: 2,
      icon: "experiment-framework-icon.svg",
      title: "Experiment Framework",
      text:
        "Our developers love coding with the Experiment Framework, an authentic way of building experiments. The Experiment Framework guarantees a standardized approach for building components and, as a result, promotes collaboration and efficiency across all teams. This robust, cross-browser approach enables a holistic end-to-end development process and helps our developers build better experiences faster, save time on one-off solutions, and accelerate the experimentation strategy.",
      link:
        "https://sogody.com/work/simplifying-samsung-s-cross-market-ab-experiences",
    },
    {
      id: 3,
      icon: "template-framework-icon.svg",
      title: "Template Framework",
      text:
        "Having templates allows us to spend significantly less time on boilerplate/core code, and more time on features that affect the bottom line. With our Template Framework, we enable everyone to ship site-agnostic experiments in a shorter amount of time while encouraging our team to focus primarily on exceptionally generic templates and leaving customizability decisions with the end-user.",
      link:
        "https://sogody.com/work/launching-the-Samsung-s22-series-through-our-new-product-testful",
    },
    {
      id: 4,
      icon: "susam-icon.svg",
      title: "S.U.S.A.M.",
      text:
        "S.U.S.A.M. (Sanity Uploader with Shopify Asset Management) is an open-source file management plugin, developed for Sanity users. Our solution unifies the Shopify media management within Sanity and elevates the headless tech stack to new integration levels for the community!",
      link: "https://susam.app/",
    },
  ])

  return (
    <Container>
      <div className="rad-slider">
        <Swiper
          direction={sliderDirection}
          pagination={{
            clickable: true,
            type: "",
          }}
          scrollbar={true}
          modules={[Pagination, Scrollbar, Mousewheel]}
          className="mySwiper"
          slidesPerView={"auto"}
          slideToClickedSlide={true}
          centeredSlides={true}
          centeredSlidesBounds={true}
          mousewheel={{
            releaseOnEdges: true
          }}
        >
          {cards.map(el => (
            <SwiperSlide>
              <Card title={el.title} icon={el.icon} text={el.text} link={el.link}></Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  )
}

const Card = ({ title, text, icon, link }) => {
  const [direction, setDirection] = useState("vertical")
  useEffect(() => {
    let dir = window.matchMedia("(min-width: 992px)").matches
      ? "vertical"
      : "horizontal"
    setDirection(dir)
  })
  // const isVertical = (typeof window !== 'undefined') && window.matchMedia("(min-width: 992px)").matches;
  return (
    // <Container>
    <Row className={`slider-card-${direction}`}>
      <Col lg={6}>
        <a href={link} className="title">
          {title}
        </a>
        <div className="description">
          {direction == "vertical" ? (
            <>{text}</>
          ) : (
            <ReactReadMoreReadLess
              charLimit={210}
              readMoreText={"Read more"}
              readLessText={"Hide"}
            >
              {text}
            </ReactReadMoreReadLess>
          )}
        </div>
      </Col>

      <Col lg={6} className="image-col">
        <img src={require(`../assets/images/${icon}`)}></img>
      </Col>
    </Row>
    // </Container>
  )
}
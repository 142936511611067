import React from "react"
import Layout from '../components/Layout'
import { Seo } from "../components/Seo";
import metaImageOg from '../assets/images/og-image.jpg';
import '../assets/scss/main.scss';
import RADBanner from "../components/RADBanner";
import RADDescription from "../components/RADDescription";
import ProprietaryTech from "../components/ProprietaryTech";
import RADLatestUpdates from "../components/RADLatestUpdates";
import RADReadMore from "../components/RADReadMore";
import RADSlider from "../components/RADSlider";
import { useLocation } from "@reach/router"
// import { globalHistory } from "@reach/router";

export default ()=> {
  const pathLocation = useLocation();
  const url = pathLocation.href;

  return (
    <Layout>
      <Seo 
        title="R&D - Sogody" 
        image={'https://sogody.com'+metaImageOg} 
        description="Our R&D team facilitates the process of turning ideas into products. We recognize opportunities in the market and then dive into research and development."
        url={url}
      />
      <div className="rad-page">
          <RADBanner />
          <RADDescription />
          <ProprietaryTech />
          <RADSlider />
          <RADLatestUpdates />
          <RADReadMore/>
      </div>
    </Layout>

)
  }



import React from "react"
import { Row, Col, Container, Figure, Button } from "react-bootstrap"
export default function () {
  return (
    <div className="rad-read-more">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6} className="left-image">
            <img
              src="https://assets.sogody.co.uk/SUSAM/KeepReadingImage.png"
              class="mask-group-icon"
              alt="Keep Reading"
              title="Keep Reading"
            />
          </Col>
          <Col xs={12} md={12} lg={6} className="text-rect">
            <h2 class="title">
              Our open-source project Sanity Uploader with Shopify Asset
              Management
            </h2>
            <p class="text" style={{marginBottom:"0px"}}>
              All the time brands experience various challenges when using
              external content management systems to work with their headless
              Shopify store. To provide the right set of tools for every brand,
              we created SUSAM — Sanity Uploader with Shopify Asset Management…
            </p>

            <div className="read-more-div">
              <Button
                href={"https://sogody.com/work/susam-our-open-source-project/"}
                className="secondary-btn-s"
              >
                Read more
                <Figure className="read-more">
                  <Figure.Image
                    src={require("../assets/images/next_service.png")}
                  />
                </Figure>
              </Button>
            </div>
            <Row>
          <div className="latest-updates-div">
            <a
              href={"https://sogody.com/updates/"}
              // className="secondary-btn-s"
            >
              View All Updates
              <Figure className="latest-updates">
                <Figure.Image
                  src={require("../assets/images/next_service.png")}
                />
              </Figure>
            </a>
          </div>
        </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React, {useState} from "react"
import { Row, Col, Container } from "react-bootstrap"
import PropTechCard from "./PropTechCard"
export default function () {
  const [cardContent, _] = useState([
    {
        id: 1,
        banner: "testful-framework-banner.png",
        icon: "testful-framework-icon.svg",
        title: "Testful",
        text:"Testful automates the delivery of multiple experiments in one go, through A/B templates, which require zero development effort to be updated and deployed across any market.",
        link:"https://testful.co.uk/"
      },
    {
      id: 2,
      banner: "experiment-framework-banner.png",
      icon: "experiment-framework-icon.svg",
      title: "Experiment Framework",
      text:"Tailored for developers, our Experiment Framework enables building and reusing cross-browser web experiences on different projects, markets, languages, and on different platforms.",
      link:"https://sogody.com/work/simplifying-samsung-s-cross-market-ab-experiences"
    },
    {
      id: 3,
      banner: "template-framework-banner.png",
      icon: "template-framework-icon.svg",
      title: "Template Framework",
      text:"Building configurable, previewable, and extendable user experiences with ease; this framework extends the Experiment Framework by giving anyone the opportunity to build user-friendly and completely customizable web features.",
      link:"https://sogody.com/work/launching-the-Samsung-s22-series-through-our-new-product-testful"
    },
    {
        id: 3,
        banner: "susam-banner.png",
        icon: "susam-icon.svg",
        title: "S.U.S.A.M.",
        text:"The pinnacle of our headless expertise, S.U.S.A.M. integrates your Sanity asset management system neatly with Shopify’s CDN. Headless Shopify stores can now easily stick with their day-to-day Shopify asset management.",
        link:"https://susam.app/"
      },
    // {
    //     id:4
    // }
  ])
  return (
    <div className="proprietary-tech">
      <Container flex>
        <Row>
          <Col md={12} lg={12} sm={12}>
            <h2 className="title">Check out our latest releases</h2>
          </Col> 
        </Row>
        <Row>
          <Col md={12} lg={12} sm={12}>
            <span className="subtitle">
              Technical innovations that are unique, inspired by our extensive
              expertise and reliable operations
            </span>
          </Col> 
        </Row>
        <Row style={{marginTop:"50px"}}>
            {
                cardContent.map(el=>(
                    <Col md={6} lg={3} sm={12} style={{paddingLeft:8, paddingRight:8}}>
                        <PropTechCard title={el.title} text={el.text} banner={el.banner} icon={el.icon} link={el.link}></PropTechCard>
                    </Col>
                ))
            }
        </Row>
      </Container>
    </div>
  )
}

import React, {useState} from "react"
import { Row, Col, Container, Figure, Button } from "react-bootstrap"
import ContactUs from "./ContactUs"

export default function () {
  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="rad-latest-updates">
      <Container>
        <Row>
          <h2 class="title">Latest updates</h2>
        </Row>
        <Row>
          <span class="subtitle">
            The space to share experiences, engage and learn from the Sogody
            team. Join the conversation by{" "}
            <a className="contact-us" href="" onClick={(e)=>{e.preventDefault(); setModalShow(true)}}>
              contacting us.
            </a>
          </span>
        </Row>
        <Row>
          <div className="read-more-div">
            <a
              href={"https://sogody.com/updates/"}
              // className="secondary-btn-s"
            >
              View All Updates
              <Figure className="read-more">
                <Figure.Image
                  src={require("../assets/images/next_service.png")}
                />
              </Figure>
            </a>
          </div>
        </Row>

        <ContactUs show = { modalShow }
        onHide = {
            () => setModalShow(false)
        }
        /> 
      </Container>
    </div>
  )
}

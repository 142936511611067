import React, { useState } from "react"
import { Row, Col, Container } from "react-bootstrap"
export default function ({title, text, banner, icon, link}) {
  return (
    <div className="proprietary-tech-card h-100" style={{paddingLeft:0}}>
      <div class="card-banner">
          <img src={require(`../assets/images/${banner}`)} />
        </div>
      <div class="content">
        <div style={{marginTop: "-38px"}}>
            <img src={require(`../assets/images/${icon}`)} width="76" />
        </div>
        <span class="card-title">{title}</span>
        <span class="card-text"> {text}</span>
        <a class="learn-more" href={link}>
          Learn More
          <img
            src={require("../assets/images/external-link-icon.svg")}
            class="learn-more-icon"
          />
        </a>
      </div>
    </div>
  )
}
